import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { useMediaQuery } from "react-responsive"
import wordcount from "../utils/wordcount"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PostBlock from "../components/PostBlock"

import styles from "../styles/404.module.scss"

export const query = graphql`
  query NOT_FOUND {
    wpgraphql {
      acf404: corePageBy(corePageId: 7982) {
        acf404 {
          title
          subtext
          blogPosts {
            header
            viewMoreText
          }
          learnMore {
            copy
            links {
              link {
                title
                url
              }
            }
          }
        }
      }

      blogPosts: posts(where: { categoryId: 4 }, first: 10) {
        nodes {
          id
          title
          uri
          slug
          date
          content(format: RENDERED)
          excerpt(format: RENDERED)
          author {
            node {
              name
              slug
              avatar(size: 100) {
                url
              }
            }
          }
          featuredImage {
            node {
              altText
              sourceUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          categories {
            edges {
              node {
                name
                slug
              }
            }
          }
        }
      }

      seoData: corePageBy(corePageId: 7982) {
        seo {
          metaDesc
          title
          opengraphTitle
          opengraphDescription
          twitterDescription
          twitterTitle
        }
      }
    }
  }
`

const NotFoundPage = ({ data }) => {
  const acf404 = data.wpgraphql.acf404.acf404
  const posts = data.wpgraphql.blogPosts.nodes

  const isMobile = useMediaQuery({ maxWidth: 1150 })
  const blocksToShow = isMobile ? 4 : 3

  return (
    <Layout>
      <SEO metaRobotsNoindex="noindex"/>
      <div className={styles.heroSection}>
        <div className={styles.heroContent}>
          <h1>{acf404.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: acf404.subtext }} />
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
